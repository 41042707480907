.about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;  
}

.about h1 {
    color: #000000;
    font-size: 28px;
}

.about h2 {
    color: #000000;
    font-size: 22px;
    margin-bottom: 20px;
}

.about p {
    font-size: 16px;
    color: #000000;
    line-height: 1.6;
}

.aboutList {
    color: #000000;
    text-align: left;
}

.navbar {
    background-color: #333;
    overflow: hidden;
}

.navbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.navbar-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

.logo {
    margin-bottom: 15px;
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.nav-links li {
    padding: 10px 20px;
}

.nav-links li a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    transition: color 0.3s;
}

.nav-links li a:hover {
    color: #f0f0f0;
}

@media (min-width: 1280px) {
    .navbar-inner {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .logo {
        margin-bottom: 0;
        margin-right: 20px; /* Add some space between logo and nav links */
    }

    .nav-links {
        flex-grow: 1; /* Allow nav links to grow and take remaining space */
        justify-content: center; /* Center the nav links */
    }

    .nav-links li a {
        font-size: 24px; /* Increase font size for larger screens */
    }
}
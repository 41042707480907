.coaching {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.coaching h1 {
    color: #2a2a72;
    font-size: 28px;
}

.coaching h2 {
    color: #1a1a1a;
    font-size: 22px;
    margin-top: 20px;
}

.coaching h3 {
    color: #333;
    font-size: 20px;
    margin-top: 18px;
}

.coaching p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 16px;
}

.coaching-appt {
    display: flex;
    justify-content: center;
    align-items: center;
}

.coaching-appt button {
    background-color: #36A9AE;
    background-image: linear-gradient(#37ADB2, #329CA0);
    text-decoration: none;
    border: 1px solid #2A8387;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 1px;
    color: #FFFFFF;
    cursor: pointer;
    display: block;
    font-family: -apple-system,".SFNSDisplay-Regular","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 17px;
    line-height: 100%;
    margin: 0;
    outline: 0;
    padding: 11px 15px 12px;
    text-align: center;
}

.yoga {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9; /* Light background for a calming effect */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.yoga h1 {
    color: #2a2a72;
    font-size: 28px;
    margin-bottom: 20px;
}

.yoga p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 16px;
}

.services {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.services h1 {
    color: #2a2a72;
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
}

.class-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.class-box {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s;
}

.class-box:hover {
    transform: scale(1.05);
}

.class-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.class-description {
    padding: 20px;
}

.class-description h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.class-description p {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
    
}

.desc-button {
    border: 2px solid;
    border-color: rgb(0, 0, 0);
    cursor:default; 
}

.dismiss-button {
    text-align: center;
    border: 2px solid;
    border-color: black;
}

.dialog-box {
    /* @media screen and (width > 1000) {
        background-color: #2a2a72;
    }; */
    background-color: #f9f9f9;
    border-color: #333;
    border: 4px solid;
    border-radius: 18px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    width: 100%;
    max-width: 300px;
    /* text-align: center; */
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    transition: transform 0.3s;
    padding: 5px 20px 20px 20px;
}

.dialog-text p, .home-text ul {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
}
